import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const SliderIndicator = () => {
  const { t } = useTranslation()
  const content = t('misc', { returnObjects: true })

  return (
    <StyledDiv className="slide-icon">
      <p>{content.slideIconCopy}</p>
      <div className="icon">
        <div className="bar" />
        <div className="circle" />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  .icon {
    position: relative;
    margin-left: ${({ theme }) => theme.spacingXS};

    .bar {
      width: 6rem;
      height: 0.1rem;
      background-color: ${({ theme }) => theme.white};
    }

    .circle {
      position: absolute;
      top: 50%;
      right: ${({ theme }) => theme.spacingXXXS};
      width: 0.8rem;
      height: 0.8rem;
      background-color: ${({ theme }) => theme.green};
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }
`
