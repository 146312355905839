import React from 'react'
import BubbleWithPlus from '../assets/images/bubble-with-plus.svg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

export const SinglePost = ({ post }) => {
  const { t } = useTranslation()
  const content = t('blogPost', { returnObjects: true })

  return (
    <Link to={post.path} language={'pt'}>
      <StyledPost className="single-post">
        <div
          className="post__image"
          style={{ backgroundImage: `url(${post.img})` }}
        />
        <div className="post__overlay" />

        <div className="post__content">
          <h5>{post.date}</h5>


          <h3>{post.title}</h3>


          <Link to={post.path} language={'pt'}>
            <div className="read-more">
              <h5>{content.readMore}</h5>
              <BubbleWithPlus />
            </div>
          </Link>
        </div>
      </StyledPost>
    </Link>
  )
}

const StyledPost = styled.article`
  position: relative;
  width: 100%;
  max-width: 70rem;
  height: 50rem;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacingXS} ${({ theme }) => theme.spacingS};
  text-align: center;
  cursor: pointer;

  :hover .post__overlay {
    background: rgba(0, 0, 0, 0.35);
  }

  .post__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  .post__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 11;
    transition: background 0.4s ease;
  }

  .post__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 111;
  }

  h5 {
    font-size: 1.8rem;
    font-weight: ${({ theme }) => theme.fontRegular};
    letter-spacing: 0.18rem;
    text-transform: uppercase;
  }

  h3 {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.fontMedium};
    font-size: 3.3rem;
    line-height: 4.2rem;
    text-transform: uppercase;
  }

  .read-more {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 3.5rem;
      width: auto;
      margin-left: ${({ theme }) => theme.spacingXS};
    }
  }

  @media screen and (max-width: 1200px) {
    height: 40rem;
  }

  @media screen and (max-width: 500px) {
    height: 30rem;

    h3 {
      font-size: 2.5rem;
      line-height: 3.1rem;
    }

    h5 {
      font-size: 1.5rem;
    }

    .read-more svg {
      height: 2.5rem;
      margin-left: ${({ theme }) => theme.spacingXXS};
    }
  }
`
