export const parseBlogPosts = (edges) =>
  edges
    .filter((edge) => edge.node.frontmatter.status)
    .map(({ node }) => ({
      id: node.id,
      path: node.frontmatter.path,
      date: node.frontmatter.date,
      ogDate: node.frontmatter.ogDate,
      title: node.frontmatter.title,
      img: node.frontmatter.img,
      description: node.frontmatter.description,
      path: node.frontmatter.path,
      status: node.frontmatter.status,
      lang: node.frontmatter.lang,
    }))
