import React from 'react'
import { SliderIndicator } from './SliderIndicator'
import Slider from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const ContentSlider = ({ children, slidesToShow = 1 }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  }

  return (
    <StyledDiv>
      <Slider {...sliderSettings}>{children}</Slider>
      <SliderIndicator />
    </StyledDiv>
  )
}

export const StyledDiv = styled.div`
  .slide-icon {
    margin-top: ${({ theme }) => theme.spacingXS};
  }
`
